<!-- src/views/assemblies/Assemblies.vue -->
<template>
  <v-container fluid style="padding-top: 0px">
    <v-card class="pa-3">
      <v-tabs v-model="activeTab" style="border-bottom: 2px solid">
        <v-tab active data-cy="tab-overview">Overview</v-tab>
        <v-spacer></v-spacer>
        <v-select
          class="mr-3 filter-select"
          max-width="200"
          v-model="tableFilter"
          :items="tableFilterData"
          item-text="title"
          item-value="value"
          variant="underlined"
          data-cy="form-value-mode"
          prepend-inner-icon="mdi-filter"
        />
        <v-text-field
          class="mr-3"
          max-width="200"
          v-model="searchQuery"
          density="compact"
          label="Search"
          prepend-inner-icon="mdi-magnify"
          variant="underlined"
          hide-details
          single-line
          data-cy="search-input"
        ></v-text-field>
        <v-btn class="btn-orange" @click="openModal('create')" data-cy="new-button">New</v-btn>
      </v-tabs>

      <div v-if="activeTab === 0" data-cy="tab-content-overview">
        <v-data-table
          :headers="tableHeaders"
          :items="filteredComponents"
          item-value="name"
          class="custom-table"
          data-cy="data-table"
          loading-text="Loading... Please wait"
          :loading="loadingData"
          v-model:items-per-page="itemsPerPage"
          :items-per-page-options="itemsPerPageOptions"
        >
          <template v-slot:item.name="{ item }">
            {{ item.name }}
          </template>
          <template v-slot:item.description="{ item }">
            {{ item.description }}
          </template>

          <template v-slot:item.type="{ item }">
            {{ typeMapping[item.type] || item.type }}
          </template>

          <template v-slot:item.actions="{ item }">
            <div class="controls-wrapper">
              <span @click="openModal('edit', item)"><i class="fa-duotone fa-solid fa-pen-to-square"></i></span>
              <span @click="confirmDeletion(item)"><i class="fa-duotone fa-solid fa-trash"></i></span>
            </div>
          </template>
        </v-data-table>
      </div>

      <div v-else data-cy="tab-content-details">
        <div class="text-center">
          <h2>Details</h2>
          <p>Information on Construction</p>
        </div>
      </div>

      <AssembliesModal
        v-if="isModalOpen"
        :title="modalTitle"
        :current-item="currentItem"
        :available-attributes="attributesData"
        :available-components="componentsData"
        :available-materials="materialsData"
        :unitOptions="unitOptions"
        @close="closeModal"
        @save="saveItem"
      />

      <Dialogs :deleteDialog="isDeleteDialogOpen" @closeDeleteDialog="closeDeleteDialog" @confirmDeleteItem="confirmDelete" />
    </v-card>
  </v-container>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import AssembliesModal from '@/views/assemblies/AssembliesModal.vue';
  import { ComponentItem, AttributeItem, MaterialItem } from '@/types/components';
  import { useAttributesStore } from '@/store/attributesStore';
  import { useUserPreferencesStore } from '@/store/userPreferencesStore';
  import Dialogs from '@/components/Dialogs.vue';
  import materialsService from '@/services/api/materialsService';
  import attributeService from '@/services/api/attributeService';
  import componentService from '@/services/api/componentService';
  import assemblyService from '@/services/api/assemblyService';
  import etcService from '@/services/api/etcService';

  export default defineComponent({
    components: {
      AssembliesModal,
      Dialogs,
    },

    data() {
      return {
        error: false,
        errorMessage: '',
        activeTab: 0,
        searchQuery: '',
        isModalOpen: false,
        isDeleteDialogOpen: false,
        modalTitle: '',
        deleteDialogText: '',
        deleteButton: true,
        currentItem: this.resetForm(), // Initialize with resetForm
        itemToDelete: null as ComponentItem | null,
        loadingData: false,
        tableHeaders: [
          { title: 'Name', value: 'name', sortable: true, width: '30%' },
          { title: 'Description', value: 'description', sortable: true, width: '30%' },
          { title: 'Code', value: 'code', sortable: true, width: '10%' },
          { title: 'Type', value: 'type', sortable: true, width: '20%' },
          { title: 'Actions', value: 'actions', sortable: false, width: '10%' },
        ],
        attributesData: [] as AttributeItem[],
        assemblyData: [] as ComponentItem[],
        componentsData: [] as ComponentItem[],
        materialsData: [] as MaterialItem[],
        tableFilter: 'All',
        tableFilterData: [
          { title: 'All', value: 'All' },
          { title: 'Blueprint', value: 'blueprint' },
          { title: 'Sub-Assembly', value: 'assembly' },
          { title: 'Code Values', value: 'values' },
        ],
        typeMapping: {
          blueprint: 'Blueprint',
          assembly: 'Sub-Assembly',
          values: 'Code Values',
        } as { [key: string]: string },
        itemsPerPage: 10,
        itemsPerPageOptions: [10, 25, 50, 100, -1],
        unitOptions: [] as any[],
      };
    },

    computed: {
      filteredComponents(): ComponentItem[] {
        return this.assemblyData.filter((item) => {
          if (this.tableFilter !== 'All' && item.type !== this.tableFilter) {
            return false;
          }
          if (this.searchQuery) {
            const query = this.searchQuery.toLowerCase();
            return Object.values(item).some((value) => String(value).toLowerCase().includes(query));
          }
          return true;
        });
      },
    },

    mounted() {
      this.getAssemblyData();
      this.getAttributesList();
      this.getComponentsData();
      this.getMaterialsData();
      this.getUnits();
      this.restoreUserPreferences();
    },

    watch: {
      tableFilter(newFilter) {
        this.saveUserPreferences({ tableFilter: newFilter });
      },
      itemsPerPage(newItemsPerPage) {
        this.saveUserPreferences({ itemsPerPage: newItemsPerPage });
      },
    },

    methods: {
      async getAttributesList() {
        try {
          const attributesStore = useAttributesStore();
          const sortedAttributes = await attributeService.getAttributesList();
          this.attributesData = sortedAttributes;
          attributesStore.setAttributesData(sortedAttributes);
        } catch (error) {
          console.error('Failed to fetch attributes:', error);
        }
      },

      async getComponentsData() {
        try {
          const sortedComponentTypes = await componentService.getComponentsList();
          this.componentsData = sortedComponentTypes;
        } catch (error: any) {
          this.error = true;
          this.errorMessage = 'Failed to fetch components';
          console.error('Failed to fetch components:', error);
        }
      },

      async getMaterialsData() {
        try {
          const response = await materialsService.getMaterialsList();
          if (response) {
            this.materialsData = response
              .map((item: any) => {
                return {
                  id: item.id,
                  name: item.odooMaterial?.description || 'No Name',
                } as MaterialItem;
              })
              .sort((a: MaterialItem, b: MaterialItem) => a.name.localeCompare(b.name));
          }
        } catch (error: any) {
          this.error = true;
          this.errorMessage = 'Failed to fetch materials';
          console.error('Failed to fetch materials:', error);
        }
      },
      async getUnits() {
        try {
          this.unitOptions = await etcService.getUnits();
        } catch (error) {
          this.$log.showError(`Failed to fetch units: ${error}`);
        }
      },
      async openModal(action: 'create' | 'edit', item: ComponentItem | null = null) {
        this.modalTitle = action === 'create' ? 'Create Assembly' : 'Edit Assembly';
        this.currentItem = item ? { ...item } : this.resetForm();
        this.isModalOpen = true;
      },

      closeModal() {
        this.isModalOpen = false;
      },

      async saveItem(preparedData: any) {
        try {
          if (!this.currentItem.id) {
            await this.createAssembly(preparedData);
          } else {
            await this.updateAssembly(this.currentItem.id, preparedData);
          }
        } catch (error) {
          console.error('Failed to save assembly:', error);
        }
      },

      confirmDeletion(item: ComponentItem) {
        if (item.components > 0) {
          this.deleteDialogText = `The Logic Module can’t be deleted, because it is used in the following Materials: {list of Materials}`;
          this.deleteButton = false;
        } else {
          this.deleteDialogText = `Are you sure you want to delete <b>${item.name}</b>?<br>The operation can’t be undone.`;
          this.deleteButton = true;
        }
        this.itemToDelete = item;
        this.isDeleteDialogOpen = true;
      },

      closeDeleteDialog() {
        this.isDeleteDialogOpen = false;
        this.itemToDelete = null;
      },

      async confirmDelete() {
        if (this.itemToDelete) {
          await this.deleteAssembly(this.itemToDelete.id!);
          this.assemblyData = this.assemblyData.filter((item) => item.id !== this.itemToDelete!.id);
          this.closeDeleteDialog();
        }
      },

      resetForm() {
        return {};
      },

      async getAssemblyData() {
        this.loadingData = true;
        try {
          const assemblyData = await assemblyService.getAssembliesList();
          this.assemblyData = assemblyData;
          this.loadingData = false;
        } catch (error: any) {
          this.loadingData = false;
          this.$log.showError(`Failed to fetch attributes: ${error}`);
        }
      },

      async createAssembly(data: any) {
        try {
          const newAssembly = await assemblyService.createAssembly(data);
          this.assemblyData.push(newAssembly);
          this.showSuccessMessage('Successfully created');
          this.closeModal();
        } catch (error: any) {
          const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred';
          this.$log.showError(`Changes were not saved due to the following error: ${errorMessage}`);
        }
      },

      async updateAssembly(id: string, data: any) {
        try {
          const updatedAssembly = await assemblyService.updateAssembly(id, data);
          this.assemblyData = this.assemblyData.map((item) => (item.id === id ? updatedAssembly : item));
          this.showSuccessMessage('Successfully updated');
          this.closeModal();
        } catch (error: any) {
          const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred';
          this.$log.showError(`Changes were not saved due to the following error: ${errorMessage}`);
        }
      },

      async deleteAssembly(id: string) {
        try {
          await assemblyService.deleteAssembly(id);
          this.getAssemblyData();
        } catch (error: any) {
          this.$log.showError(`Failed to delete assembly with: ${error}`);
        }
      },

      // transformComponentData(item: ComponentItem) {
      //   const transformedData: any = {
      //     name: item.name,
      //     componentTypeName: item.componentTypeName,
      //     inheritsFrom: item.inheritFrom.map((inherit) => inherit.id),
      //     attributes: item.attributes
      //       .filter(({ value }) => !!value)
      //       .map((attribute) => ({
      //         attributeId: attribute.id,
      //         value: attribute.value || null,
      //       })),
      //     contains: item.contains.map((containsItem) => ({
      //       componentTypeId: containsItem.id,
      //       qty: containsItem.qty,
      //     })),
      //   };

      //   if (item.productFamilyCode?.id) {
      //     transformedData.productFamily = {
      //       id: item.productFamilyCode.id,
      //       code: item.productFamilyCode.code,
      //       name: item.productFamilyCode.label,
      //     };
      //   }

      //   if (item.description) {
      //     transformedData.description = item.description;
      //   }

      //   return transformedData;
      // },

      showSuccessMessage(message: string) {
        this.$swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: message,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      },

      showErrorMessage(error: any) {
        const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred';
        console.error(`Changes were not saved due to the following error: ${errorMessage}`);
      },

      saveUserPreferences(preferences: { tableFilter?: string; itemsPerPage?: number }) {
        const userPreferencesStore = useUserPreferencesStore();
        if (preferences.tableFilter !== undefined) {
          userPreferencesStore.setAssembliesTableFilterStore(preferences.tableFilter);
        }
        if (preferences.itemsPerPage !== undefined) {
          userPreferencesStore.setAssembliesTableItemsPerPage(preferences.itemsPerPage);
        }
      },

      restoreUserPreferences() {
        const userPreferencesStore = useUserPreferencesStore();
        this.tableFilter = userPreferencesStore.pageTableFilters.assembliesTableFilterStore;
        this.itemsPerPage = userPreferencesStore.itemsPerPage.assembliesTableItemsPerPage;
      },
    },
  });
</script>

<style scoped>
  .v-data-table {
    margin-top: 20px;
  }
  .controls-wrapper {
    display: flex;
    justify-content: space-around;
    font-size: 18px;
  }
  .controls-wrapper span {
    cursor: pointer;
  }
  .controls-wrapper span > i:hover {
    color: orange;
  }
  .controls-wrapper span > i.fa-pen-to-square:hover {
    color: rgb(64, 64, 194);
  }
  .controls-wrapper span > i.fa-trash:hover {
    color: red;
  }
  .filter-select {
    margin: -9px 0px 0px 0px;
  }
  :deep(.v-table thead) {
    background-color: #f5f5f5;
  }
  ::v-deep(.v-data-table thead) {
    background-color: #f5f5f5;
  }
  ::v-deep(.v-data-table tr:hover) {
    background-color: #f7f7f7;
  }
</style>
