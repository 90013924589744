<!-- src/components/LeftSideBar.vue -->
<template>
  <v-navigation-drawer app v-model="drawer" :mini-variant.sync="miniVariant" permanent :width="miniVariant ? 80 : 256" data-cy="navigation-drawer">
    <v-toolbar flat>
      <v-toolbar-title v-if="!miniVariant"></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="toggleMiniVariant" class="menu-toggle-button">
        <i :class="miniVariant ? 'fa-solid fa-chevron-right' : 'fa-solid fa-chevron-left'"></i>
      </v-btn>
    </v-toolbar>
    <v-list data-cy="list">
      <!-- Generate -->
      <v-list-item>
        <v-btn :class="['menu-button', { 'active-btn': isActive('Generate') }]" block class="align-left" @click="navigateTo('Generate')">
          <n-tooltip trigger="hover" placement="right">
            <template #trigger>
              <i class="fa-duotone fa-solid fa-gears"></i>
            </template>
            Generate BOM
          </n-tooltip>
          <span v-if="!miniVariant" class="item-title">Generate BOM</span>
        </v-btn>
      </v-list-item>
      <!-- B.O.Ms -->
      <v-list-item data-cy="list-item-boms">
        <v-btn :class="['menu-button', { 'active-btn': isActive('Boms') }]" block class="align-left" @click="navigateTo('Boms')" data-cy="btn-boms">
          <n-tooltip trigger="hover" placement="right">
            <template #trigger>
              <i class="fa-duotone fa-solid fa-rectangle-list"></i>
            </template>
            Saved BOMs
          </n-tooltip>
          <span v-if="!miniVariant" class="item-title" data-cy="title-boms">Saved BOMs</span>
        </v-btn>
      </v-list-item>

      <!-- Assemblies -->
      <v-list-item data-cy="list-item-assemblies">
        <v-btn :class="['menu-button', { 'active-btn': isActive('Assemblies') }]" block class="align-left" @click="navigateTo('Assemblies')" data-cy="btn-assemblies">
          <n-tooltip trigger="hover" placement="right">
            <template #trigger>
              <i class="fa-regular fa-share-nodes"></i>
            </template>
            Assemblies
          </n-tooltip>
          <span v-if="!miniVariant" class="item-title" data-cy="title-assemblies">Assemblies</span>
        </v-btn>
      </v-list-item>

      <!-- Materials -->
      <v-list-item data-cy="list-item-materials">
        <v-btn :class="['menu-button', { 'active-btn': isActive('Materials') }]" block class="align-left" @click="navigateTo('Materials')" data-cy="btn-materials">
          <n-tooltip trigger="hover" placement="right">
            <template #trigger>
              <i class="fa-duotone fa-solid fa-layer-group"></i>
            </template>
            Raw Materials
          </n-tooltip>
          <span v-if="!miniVariant" class="item-title" data-cy="title-materials">Raw Materials</span>
        </v-btn>
      </v-list-item>

      <!-- Logic Modules -->
      <v-list-item data-cy="list-item-components">
        <v-btn :class="['menu-button', { 'active-btn': isActive('Logic Modules') }]" block class="align-left" @click="navigateTo('Logic Modules')" data-cy="btn-components">
          <n-tooltip trigger="hover" placement="right">
            <template #trigger>
              <i class="fa-duotone fa-solid fa-network-wired"></i>
            </template>
            Logic Modules
          </n-tooltip>
          <span v-if="!miniVariant" class="item-title" data-cy="title-logic-modules">Logic Modules</span>
        </v-btn>
      </v-list-item>

      <!-- Attributes -->
      <v-list-item data-cy="list-item-attributes">
        <v-btn :class="['menu-button', { 'active-btn': isActive('Attributes') }]" block class="align-left" @click="navigateTo('Attributes')" data-cy="btn-attributes">
          <n-tooltip trigger="hover" placement="right">
            <template #trigger>
              <i class="fa-solid fa-table-list" style="margin-left: 4px"></i>
            </template>
            Attributes
          </n-tooltip>
          <span v-if="!miniVariant" class="item-title" data-cy="title-attributes">Attributes</span>
        </v-btn>
      </v-list-item>

      <!-- B.O.Ms -->
      <!-- <v-list-item data-cy="list-item-boms">
        <v-btn disabled :class="['menu-button', { 'active-btn': isActive('Boms') }]" block class="align-left" @click="navigateTo('Boms')" data-cy="btn-boms">
          <span v-if="!miniVariant" class="item-title" data-cy="title-boms">B.O.Ms</span>
        </v-btn>
      </v-list-item> -->

      <!-- <hr /> -->
      <!-- Settings -->
      <!-- <v-list-item>
        <v-btn :class="['menu-button', { 'active-btn': isActive('Settings') }]" block class="align-left" @click="navigateTo('Settings')">
          <span v-if="!miniVariant" class="item-title">Settings</span>
        </v-btn>
      </v-list-item> -->
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
  import { defineComponent, ref, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  export default defineComponent({
    name: 'LeftSideBar',

    setup() {
      const route = useRoute();
      const router = useRouter();
      const drawer = ref(true);
      const miniVariant = ref(true);

      const navigateTo = (page: string) => {
        if (!isDisabled(page)) {
          router.push({ name: page });
        }
      };

      const isActive = (page: string) => route.name === page;

      const isDisabled = (page: string) => {
        return page === 'Boms2';
      };

      const toggleMiniVariant = () => {
        miniVariant.value = !miniVariant.value;
      };

      watch(miniVariant, () => {
        document.documentElement.style.setProperty('--v-layout-left', miniVariant.value ? '80px' : '256px');
      });

      return {
        drawer,
        miniVariant,
        navigateTo,
        isActive,
        toggleMiniVariant,
      };
    },
  });
</script>

<style scoped>
  .align-left {
    justify-content: flex-start;
    text-align: left;
    padding: 0px 12px;
  }
  .item-title {
    position: absolute;
    left: 49px;
  }
  .icon {
    font-size: 1.2rem;
  }
  .active-btn {
    background-color: orange !important;
  }
  .menu-toggle-button {
    margin-right: 16px !important;
  }
  .menu-button {
    overflow-wrap: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: normal;
    word-wrap: break-word;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.009375em;
    line-height: 1.5;
    text-transform: none;
  }
  .menu-button:disabled {
    background-color: none;
    overflow-wrap: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: normal;
    word-wrap: break-word;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.009375em;
    line-height: 1.5;
    text-transform: none;
  }

  .menu-button i {
    font-size: 20px;
    position: absolute;
    left: 10px;
  }
</style>
