<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card>
          <v-card-title class="headline text-center">Profile</v-card-title>
          <v-divider></v-divider>

          <v-card-text>
            <v-avatar color="grey lighten-2" size="100" class="mx-auto my-3">
              <span class="text-h4 text-dark">{{ initials }}</span>
            </v-avatar>
            <div class="profile-info text-center">
              <h3 class="profile-name">{{ user?.name }}</h3>
              <p class="text-caption profile-email">{{ user?.email }}</p>
            </div>
            <v-divider class="my-3"></v-divider>

            <div v-if="user" class="additional-info">
              <p>
                <strong>Nickname:</strong>
                {{ user?.nickname }}
              </p>
              <p>
                <strong>Updated At:</strong>
                {{ user?.updated_at ? new Date(user.updated_at).toLocaleString() : 'N/A' }}
              </p>
              <p>
                <strong>Sub:</strong>
                {{ user?.sub }}
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
  import { defineComponent, computed } from 'vue';
  import { useAuth0 } from '@auth0/auth0-vue';

  export default defineComponent({
    name: 'Profile',
    setup() {
      const { user } = useAuth0();

      const initials = computed(() => {
        if (user.value && user.value.name) {
          const names = user.value.name.split(' ');
          return names.map((n) => n[0]).join('');
        }
        return '';
      });

      return {
        user,
        initials,
      };
    },
  });
</script>

<style scoped>
  .text-center {
    text-align: center;
  }

  v-avatar {
    border-radius: 50%;
    overflow: hidden;
  }

  .profile-info {
    margin-top: 10px;
  }

  .profile-name {
    font-size: 20px;
    font-weight: 600;
    color: #444;
    margin-bottom: 4px;
  }

  .profile-email {
    color: #666;
    font-size: 14px;
  }

  .additional-info p {
    margin: 8px 0;
    font-size: 14px;
    color: #555;
  }
</style>
