<template>
  <div class="generate-drawings">
    <v-row>
      <v-col cols="12" md="12" class="mt-4"></v-col>
    </v-row>
    <v-row>
      <!-- No Data -->
      <v-col v-if="!pngUrl && !isWaiting" cols="12" md="12" class="content-col">
        <div class="no-data mt-6" data-cy="details-content">
          <h2 data-cy="details-title">No Drawings</h2>

          <p class="text-center" data-cy="details-description">
            <v-btn :disabled="isWaiting || isRequestDisabled" @click="handleRequestClick" class="btn-orange full-width bold-text mt-5">Request DRAWING</v-btn>
          </p>
        </div>
      </v-col>
      <!-- Waiting -->
      <v-col v-if="isWaiting && !pngUrl" cols="12" md="12" class="content-col">
        <div class="no-data" data-cy="details-content">
          <h1 class="highlight">Please Wait</h1>
          <h3>This may take some time, approximately 5 minutes on average.</h3>
          <div class="dots">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </v-col>
      <!--  Image  -->
      <v-col v-if="pngUrl" cols="12" md="12" class="content-col">
        <div>
          <!-- <n-image width="100%" :src="pngUrl" :previewed-img-props="{ style: { border: '8px solid white' } }" /> -->
          <vue-pdf-app v-if="pdfFileUrl" :pdf="pdfFileUrl" style="width: 100%; height: 76vh" theme="light" :config="config" />
          <div class="float-btn">
            <v-btn :disabled="!pngUrl || !visioFileUrl" @click="downloadVisio" class="btn-orange download-visio-btn">Download Visio</v-btn>
          </div>
        </div>
      </v-col>

      <!-- <v-col cols="12" md="2" class="sidebar-col">
        <v-btn :disabled="isWaiting || isRequestDisabled" @click="handleRequestClick" class="btn-orange full-width bold-text">Request DRAWING</v-btn>
        <h3 class="mt-4">Download:</h3>
        <hr class="divider" />
        <v-row>
          <v-col cols="12" md="4" class="sidebar-btn-col">
            <v-btn :disabled="!pngUrl" @click="downloadImage" class="btn-orange full-width">
              <i class="fa-duotone fa-solid fa-image"></i>
            </v-btn>
          </v-col>
          <v-col cols="12" md="4" class="sidebar-btn-col">
            <v-btn :disabled="!pngUrl || !pdfFileUrl" @click="downloadPDF" class="btn-orange full-width">
              <i class="fa-solid fa-file-pdf"></i>
            </v-btn>
          </v-col>
          <v-col cols="12" md="4" class="sidebar-btn-col">
            <v-btn :disabled="!pngUrl || !visioFileUrl" @click="downloadVisio" class="btn-orange full-width">
              <i class="fa-duotone fa-light fa-file-vector"></i>
            </v-btn>
          </v-col>
        </v-row>
      </v-col> -->
    </v-row>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import drawingService from '@/services/api/drawingService';
  import VuePdfApp from 'vue3-pdf-app';
  import 'vue3-pdf-app/dist/icons/main.css';

  export default defineComponent({
    name: 'GenerateDrawings',
    components: {
      VuePdfApp,
    },
    data() {
      return {
        pngUrl: null as string | null,
        renderRequestId: '' as string,
        isWaiting: false,
        pollingIntervalId: null as number | null,
        isRequestDisabled: false,
        pdfFileUrl: null as string | null,
        visioFileUrl: null as string | null,
        config: {
          toolbar: {
            toolbarViewerLeft: false,
            openFile: false,
            viewBookmark: false,
          },
        },
      };
    },

    props: {
      items: {
        type: Array as () => any[],
        required: false,
        default: () => [],
      },
    },

    methods: {
      handleRequestClick() {
        this.resetState();
        const requestData = this.items[0];
        this.sendRenderRequest(requestData);
      },

      resetState() {
        this.pngUrl = null;
        this.renderRequestId = '';
        this.isWaiting = false;
        this.clearSessionData();
      },

      async sendRenderRequest(data: any) {
        try {
          const response = await drawingService.requestRender(data);
          if (response?.code) {
            this.renderRequestId = response.code;
            sessionStorage.setItem('renderID', this.renderRequestId);
            this.initiatePolling();
            this.fetchDrawing();
            this.isWaiting = true;
          } else {
            this.$log.showError('No code received from server');
          }
        } catch (error) {
          this.$log.showError('Error sending render request', error);
        }
      },

      initiatePolling() {
        if (this.pollingIntervalId) {
          clearInterval(this.pollingIntervalId);
        }

        this.pollingIntervalId = setInterval(this.fetchDrawing, 10000);
      },

      async fetchDrawing() {
        if (!this.renderRequestId) {
          this.$log.showError('No renderRequestId found.');
          return;
        }

        try {
          const response = await drawingService.getRender(this.renderRequestId);

          if (response?.status === 'error') {
            this.$log.showError('Error fetching drawing:', response.message);
            this.stopPolling();
            return;
          }

          if (response?.error) {
            this.$log.showError('Error fetching drawing:', response.error);
            this.stopPolling();
            return;
          }

          if (response?.pngUrl) {
            this.handleSuccessfulFetch(response);
          } else {
            this.$log.info('Drawing is still being processed');
          }
        } catch (error) {
          this.$log.showError('Error fetching drawing', error);
          this.cleanupOnError();
        }
      },

      handleSuccessfulFetch(response: any) {
        this.pngUrl = response.pngUrl;
        this.pdfFileUrl = response.pdfUrl;
        this.visioFileUrl = response.vsdxUrl;

        this.$log.info('Drawing fetched successfully:', this.pngUrl);
        this.isWaiting = false;

        if (this.pngUrl) {
          sessionStorage.setItem('pngUrl', this.pngUrl);
        }
        if (this.pdfFileUrl) {
          sessionStorage.setItem('pdfUrl', this.pdfFileUrl);
        }
        if (this.visioFileUrl) {
          sessionStorage.setItem('vsdxUrl', this.visioFileUrl);
        }

        this.stopPolling();
      },

      stopPolling() {
        this.isWaiting = false;
        if (this.pollingIntervalId) {
          clearInterval(this.pollingIntervalId);
          this.pollingIntervalId = null;
        }
        sessionStorage.removeItem('renderID');
      },

      cleanupOnError() {
        this.resetState();
        if (this.pollingIntervalId) {
          clearInterval(this.pollingIntervalId);
          this.pollingIntervalId = null;
        }
      },

      downloadImage() {
        if (!this.pngUrl) return;
        this.downloadFile(this.pngUrl, 'rendered_image.png');
      },

      downloadPDF() {
        if (!this.pdfFileUrl) return;
        this.downloadFile(this.pdfFileUrl, 'rendered_drawing.pdf');
      },

      downloadVisio() {
        if (!this.visioFileUrl) return;
        this.downloadFile(this.visioFileUrl, 'rendered_drawing.vsdx');
      },

      downloadFile(fileUrl: string, filename: string) {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = filename;
        link.click();
        this.$log.info(`File downloaded: ${filename}`);
      },

      clearSessionData() {
        sessionStorage.removeItem('pngUrl');
        sessionStorage.removeItem('renderID');
        sessionStorage.removeItem('pdfUrl');
        sessionStorage.removeItem('vsdxUrl');
      },
    },

    mounted() {
      const storedRenderID = sessionStorage.getItem('renderID');
      const storedPngUrl = sessionStorage.getItem('pngUrl');

      if (storedPngUrl) {
        this.pngUrl = storedPngUrl;
        this.pdfFileUrl = sessionStorage.getItem('pdfUrl');
        this.visioFileUrl = sessionStorage.getItem('vsdxUrl');
        this.$log.info('Loaded drawing link from sessionStorage:', storedPngUrl);
      }

      if (storedRenderID && !this.pngUrl) {
        this.renderRequestId = storedRenderID;
        this.$log.info('Found renderID in sessionStorage, starting polling:', this.renderRequestId);
        this.initiatePolling();
        this.isWaiting = true;
      }

      if (!this.items || this.items.length === 0) {
        this.isRequestDisabled = true;
        this.$log.warn('No items provided. Disabling request button.');
      }
    },

    beforeUnmount() {
      if (this.pollingIntervalId) {
        clearInterval(this.pollingIntervalId);
      }
    },
  });
</script>

<style scoped>
  .content-col {
    padding: 0px 12px;
    margin-top: -20px;
  }

  .sidebar-col {
    padding: 0px 12px;
    text-align: center;
  }

  .sidebar-btn-col {
    padding: 0px 12px;
    text-align: center;
  }

  .no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .mini-btn {
    font-size: 0.8rem;
    border: 1px solid orange;
    padding: 2px 5px;
    border-radius: 5px;
  }

  .highlight {
    color: orange;
  }

  .btn-orange {
    background-color: orange;
    color: #fff;
  }

  .full-width {
    width: 100%;
  }

  .bold-text {
    font-weight: bold;
  }

  .divider {
    border-top: 1px solid #ccc;
    margin: 16px 0;
  }

  .dots {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 50px auto;
  }

  .dots span {
    width: 15px;
    height: 15px;
    background-color: orange;
    border-radius: 50%;
    animation: bounce 1.2s infinite;
  }

  .dots span:nth-child(1) {
    animation-delay: 0s;
  }

  .dots span:nth-child(2) {
    animation-delay: 0.2s;
  }

  .dots span:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes bounce {
    0%,
    80%,
    100% {
      transform: scale(1);
    }
    40% {
      transform: scale(1.5);
    }
  }

  .float-btn {
    position: absolute;
    bottom: 6%;
    right: 2%;
  }

  .pdf-app.light {
    --pdf-app-background-color: white;
  }

  :deep(.pdf-app .pdfViewer .page) {
    border-image: none !important;
  }
</style>
