<template>
  <v-dialog v-model="isModalOpen" max-width="1100px" persistent>
    <v-card>
      <v-card-title class="modal-title">
        <span class="text-h5">Edit Branches</span>
        <v-icon small @click="close" class="close-icon">mdi-close</v-icon>
      </v-card-title>

      <!-- <div v-if="hasBranchItems" class="align-center" style="padding: 0px 8px; display: inline-flex; align-items: center">
        <v-checkbox v-model="showBranchItemsFirst" label="Show Branch Items First" class="ml-2" dense hide-details style="margin-right: 8px" />
        <v-checkbox v-model="showOnlyBranchItems" label="Show Only Branch Items" class="ml-2" dense hide-details />
      </div> -->
      <div class="edit-branch-tollerance" style="margin-bottom: 16px">
        <v-row class="mr-4 ml-2 mt-2">
          <v-col cols="12" md="3" class="ml-0 pl-0">
            <v-checkbox v-if="hasBranchItems" v-model="showBranchItemsFirst" label="Show Branch Items First" class="ml-2" dense hide-details style="margin-right: 8px" />
          </v-col>
          <v-col cols="12" md="3" class="ml-0 pl-0">
            <v-checkbox v-if="hasBranchItems" v-model="showOnlyBranchItems" label="Show Only Branch Items" class="ml-2" dense hide-details />
          </v-col>
          <v-col cols="12" md="3"></v-col>
          <v-col cols="12" md="1"><v-text-field :model-value="breakoutAdditionalData[0]?.end" label="End" variant="underlined" type="text" reverse hide-details readonly /></v-col>
          <v-col cols="12" md="2"><v-text-field v-model="branchTolerance" label="Branch Tolerance (mm)" variant="underlined" type="text" reverse hide-details /></v-col>
        </v-row>
      </div>

      <v-card-text :style="{ paddingTop: !hasBranchItems ? '16px' : '0px' }">
        <div class="branches-table">
          <div class="table-row header">
            <div class="table-cell" style="width: 6%">ID</div>
            <div class="table-cell" style="width: 15%">Length (mm)</div>
            <div class="table-cell" style="width: 15%">Length (in)</div>
            <div class="table-cell" style="width: 15%">Diameter (mm)</div>
            <div class="table-cell" style="width: 15%">Jacket Color</div>
            <div class="table-cell" style="width: 15%">Label Color</div>
            <div class="table-cell" style="width: 19%">Label</div>
          </div>

          <v-virtual-scroll :items="filteredChildren" item-height="50" height="500" class="virtual-scroll-container">
            <template v-slot="{ item: child }">
              <div class="table-row" :key="child.id">
                <div class="table-cell" style="width: 6%">
                  <span :class="{ branch: !/'/.test(child.name) }">
                    {{ child.name }}
                  </span>
                </div>
                <div class="table-cell">
                  <v-text-field
                    v-model="child.length.mm"
                    variant="underlined"
                    reverse
                    @input="updateFromMillimeters(child)"
                    @keydown="
                      (e: any) =>
                        /^\d*\.?\d*$/.test(e.target.value + e.key) || ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(e.key)
                          ? updateFromMillimeters(child)
                          : e.preventDefault()
                    "
                    hide-details
                  />
                </div>
                <div class="table-cell" style="width: 15%">
                  <v-text-field
                    v-model="child.length.inches"
                    variant="underlined"
                    reverse
                    @input="updateFromInches(child)"
                    @keydown="
                      (e: any) =>
                        /^\d*\.?\d*$/.test(e.target.value + e.key) || ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(e.key)
                          ? updateFromInches(child)
                          : e.preventDefault()
                    "
                    hide-details
                  />
                </div>
                <div class="table-cell" style="width: 15%">
                  <v-combobox
                    v-model="child.diameter.value"
                    :items="outsideDiameterOptions"
                    item-value="item"
                    item-text="item"
                    variant="underlined"
                    reverse
                    :rules="[(value) => /^\d*\.?\d*$/.test(value) || value === NA_ATTRIBUTE_VALUE || 'Only numbers and a dot are allowed']"
                    hide-details
                    @update:modelValue="(value) => handleEmptyValue(child, 'diameter', value)"
                  ></v-combobox>
                </div>
                <div class="table-cell" style="width: 15%">
                  <i
                    class="fa-solid fa-square"
                    :style="{
                      display: 'inline-block',
                      verticalAlign: 'middle',
                      marginTop: '20px',
                      ...getColor(child.jacketColor.value),
                      border: getColor(child.jacketColor.value).color === 'rgba(0, 0, 0, 0)' ? 'none' : '1px solid #d1cfcf',
                    }"
                  ></i>
                  &nbsp;
                  <v-select
                    v-model="child.jacketColor.value"
                    :items="jacketColorOptions"
                    item-value="item"
                    item-text="item"
                    variant="underlined"
                    @update:modelValue="(value) => handleEmptyValue(child, 'jacketColor', value)"
                    clearable
                    style="display: inline-block; vertical-align: middle; width: 80%"
                    hide-details
                  ></v-select>
                </div>
                <div class="table-cell" style="width: 15%">
                  <i
                    class="fa-solid fa-square"
                    :style="{
                      display: 'inline-block',
                      verticalAlign: 'middle',
                      marginTop: '20px',
                      ...getColor(child.labelColor.value),
                      border: getColor(child.labelColor.value).color === 'rgba(0, 0, 0, 0)' ? 'none' : '1px solid #d1cfcf',
                    }"
                  ></i>
                  &nbsp;
                  <v-select
                    v-model="child.labelColor.value"
                    :items="labelColorOptions"
                    item-value="item"
                    item-text="item"
                    variant="underlined"
                    clearable
                    :disabled="!/'/.test(child.name)"
                    @update:modelValue="(value) => handleEmptyValue(child, 'labelColor', value)"
                    style="display: inline-block; vertical-align: middle; width: 80%"
                    hide-details
                  ></v-select>
                </div>
                <div class="table-cell" style="width: 20%">
                  <v-text-field
                    :autofocus="false"
                    v-model="child.labelText.value"
                    variant="underlined"
                    :disabled="!/'/.test(child.name)"
                    hide-details
                    clearable
                    @update:modelValue="(value) => handleEmptyValue(child, 'labelText', value)"
                  />
                </div>
              </div>
            </template>
          </v-virtual-scroll>
        </div>
      </v-card-text>
      <v-card-actions class="btn-modal-bottom">
        <v-spacer></v-spacer>
        <v-btn @click="close" class="btn-cancel">Cancel</v-btn>
        <v-btn @click="save" class="btn-save">Apply</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { defineComponent, PropType, watch } from 'vue';
  import _ from 'lodash';
  import { debounce } from 'lodash';

  interface AttributeValue {
    id?: string;
    attributeId?: string;
    description: string;
    value: string;
  }

  interface NestedChild {
    componentTypeId: string;
    attributeValues?: AttributeValue[];
  }

  interface Child {
    id: string;
    name: string;
    componentTypeId: string;
    attributeValues: AttributeValue[];
    children?: NestedChild[];
  }

  const NA_ATTRIBUTE_VALUE = 'N/A';

  export default defineComponent({
    props: {
      breakout: {
        type: Object as PropType<any>,
        required: true,
      },
      availableAttributes: {
        type: Array as PropType<any[]>,
        required: true,
      },
      isModalOpen: {
        type: Boolean,
        required: true,
      },
      breakoutAdditionalData: {
        type: Object as PropType<any>,
        required: true,
      },
    },
    emits: ['close', 'save'],

    data() {
      return {
        NA_ATTRIBUTE_VALUE,
        originalBreakout: {} as any, // Original breakout data to reset changes on cancel
        editedBreakout: {} as any, // Cloned breakout for modifications
        nestedChildren: [] as any[], // Store children for editing
        showOnlyBranchItems: false,
        showBranchItemsFirst: true,
        jacketColorOptions: [] as string[],
        labelColorOptions: [] as string[],
        branchTolerance: '',
        outsideDiameterOptions: ['0.6', '0.8', '0.9', '1.6', '2', '2.6', '3', '3.6'],
        colorHexMap: {
          Aqua: '#00BCD4',
          Black: '#212121',
          Blue: '#2196F3',
          Brown: '#795548',
          Green: '#4CAF50',
          Grey: '#9E9E9E',
          NONE: 'rgba(0, 0, 0, 0)',
          Orange: '#FF9800',
          Purple: '#9C27B0',
          Red: '#F44336',
          Slate: '#607D8B',
          White: '#FFFFFF',
          Yellow: '#FFEB3B',
        },
        cId_branch: '66f93b60530fba005e6f5c86',
        cId_branch_leg: '66cdd9fb5f893788612b3c39',
        cId_furcationTube: '66fae2a266978e5c7a25c3c5',
        cId_label: '66e02aaf7e32cbb9df8e2086',
        attrId_color: '66bbbfdddbeb6f88b8f20386',
        attrId_Branch_Tolerance: '672a197767571b92d8310bfd',
      };
    },

    watch: {
      isModalOpen(newVal) {
        if (newVal) {
          this.initializeBreakoutData();
        }
      },
    },
    computed: {
      filteredChildren() {
        if (!this.showBranchItemsFirst) {
          return this.nestedChildren;
        }

        const firstLevelChildren = this.nestedChildren.filter((child) => child.componentTypeId === this.cId_branch);
        const sortedFirstLevelChildren = firstLevelChildren.sort((a, b) => {
          const hasDotA = a.label.includes('.');
          const hasDotB = b.label.includes('.');

          if (!hasDotA && hasDotB) return -1;
          if (hasDotA && !hasDotB) return 1;

          return a.label.localeCompare(b.label);
        });
        const nestedChildren = this.nestedChildren.filter((child) => child.componentTypeId !== this.cId_branch);

        let children = [...sortedFirstLevelChildren, ...nestedChildren];

        if (this.showOnlyBranchItems) {
          children = children.filter((child) => !/'/.test(child.name));
        }

        children.sort((a, b) => {
          const aIsFirstLevel = a.componentTypeId === this.cId_branch;
          const bIsFirstLevel = b.componentTypeId === this.cId_branch;
          return aIsFirstLevel === bIsFirstLevel ? 0 : aIsFirstLevel ? -1 : 1;
        });

        return children;
      },
      hasBranchItems() {
        return this.nestedChildren.some((child) => !/'/.test(child.name));
      },
    },
    methods: {
      initializeBreakoutData() {
        this.originalBreakout = _.cloneDeep(this.breakout);
        this.editedBreakout = _.cloneDeep(this.breakout);
        this.initializeColors();
        this.initializeNestedChildren();
      },
      initializeColors() {
        const getUniqueSortedValues = (values: string[]): string[] => {
          return Array.from(new Set(values)).sort((a, b) => a.localeCompare(b));
        };

        const branchToleranceAttr = this.breakout.attributeValues.find((attr: AttributeValue) => attr.attributeId === this.attrId_Branch_Tolerance);
        this.branchTolerance = branchToleranceAttr ? branchToleranceAttr.value : '';

        const jacketColor = this.availableAttributes.find((attr) => attr.id === this.attrId_color);
        this.jacketColorOptions = getUniqueSortedValues(jacketColor?.values?.map((attr: any) => attr.value) || []);

        const labelColor = this.availableAttributes.find((attr) => attr.id === this.attrId_color);
        this.labelColorOptions = getUniqueSortedValues(labelColor?.values?.map((attr: any) => attr.value) || []);
      },
      initializeNestedChildren() {
        const fetchBranchLegs = (children: any[]) => {
          let result: any[] = [];
          children.forEach((child) => {
            if (child.componentTypeId === this.cId_branch_leg || child.componentTypeId === this.cId_branch) {
              const length = this.getAttributeValue(child, 'Length');
              const diameter = this.getChildAttributeValue(child, this.cId_furcationTube, 'FTube Outside Diameter');
              const jacketColor = this.getChildAttributeValue(child, this.cId_furcationTube, 'Color');
              const labelColor = this.getChildAttributeValue(child, this.cId_label, 'Color');
              const labelText = this.getChildAttributeValue(child, this.cId_label, 'Label Text');

              result.push({
                ...child,
                length: {
                  mm: length.value !== NA_ATTRIBUTE_VALUE ? length.value : null,
                  inches: length.value !== NA_ATTRIBUTE_VALUE ? this.toInches(length.value) : null,
                },
                diameter,
                jacketColor,
                labelColor,
                labelText,
              });
            }
            if (child.children) {
              result = result.concat(fetchBranchLegs(child.children));
            }
          });
          return result;
        };

        this.nestedChildren = fetchBranchLegs(this.editedBreakout.children || []);
      },
      getAttributeValue(child: any, description: string) {
        const attr = child?.attributeValues?.find((attr: any) => attr.description === description);
        return attr ? { value: attr.value } : { value: NA_ATTRIBUTE_VALUE };
      },

      getChildAttributeValue(child: any, componentTypeId: string, description: string) {
        const nestedChild = child.children?.find((nestedChild: any) => nestedChild.componentTypeId === componentTypeId);
        return this.getAttributeValue(nestedChild, description);
      },

      toInches(mm: number) {
        return (mm / 25.4).toFixed(2);
      },

      toMillimeters(inches: number) {
        return (inches * 25.4).toFixed(2);
      },

      updateFromMillimeters: debounce(function (this: any, child) {
        child.length.inches = this.toInches(child.length.mm);
      }, 300),

      updateFromInches: debounce(function (this: any, child) {
        child.length.mm = this.toMillimeters(child.length.inches);
      }, 300),
      close() {
        this.$emit('close');
      },

      save() {
        const branchToleranceAttr = this.editedBreakout.attributeValues.find((attr: AttributeValue) => attr.attributeId === this.attrId_Branch_Tolerance);

        if (branchToleranceAttr) {
          branchToleranceAttr.value = this.branchTolerance || this.NA_ATTRIBUTE_VALUE;
        } else {
          this.editedBreakout.attributeValues.push({
            attributeId: this.attrId_Branch_Tolerance,
            description: 'Branch Tolerance',
            value: this.branchTolerance || this.NA_ATTRIBUTE_VALUE,
          });
        }
        this.updateBreakoutChildren();
        this.$emit('save', this.editedBreakout);
        // console.log('orig data', this.originalBreakout);
        // console.log('save data', this.editedBreakout);
      },

      cancel() {
        this.editedBreakout = JSON.parse(JSON.stringify(this.originalBreakout));
        this.initializeNestedChildren();
        this.$emit('close');
      },

      updateBreakoutChildren() {
        const setAttributeValue = (child: any, description: string, value?: any) => {
          const attr = child?.attributeValues?.find((attr: AttributeValue) => attr.description === description);
          if (attr) {
            attr.value = value || NA_ATTRIBUTE_VALUE;

            if (attr.value !== NA_ATTRIBUTE_VALUE) {
              attr.isUserModified = true;
            }
          }
        };

        const syncAttributes = (child: any, updatedChild: any) => {
          child.name = updatedChild.name;

          setAttributeValue(child, 'Length', updatedChild.length.mm);

          child.children = child.children || [];

          let furcationTubeChild = child.children.find((nestedChild: any) => nestedChild.componentTypeId === this.cId_furcationTube);
          let labelChild = child.children.find((nestedChild: any) => nestedChild.componentTypeId === this.cId_label);

          if (furcationTubeChild) {
            setAttributeValue(furcationTubeChild, 'Color', updatedChild.jacketColor.value);
            setAttributeValue(furcationTubeChild, 'FTube Outside Diameter', updatedChild.diameter.value);
          }

          if (labelChild) {
            setAttributeValue(labelChild, 'Color', updatedChild.labelColor.value);
            setAttributeValue(labelChild, 'Label Text', updatedChild.labelText.value);
          }
        };

        const traverseAndUpdate = (child: any) => {
          const updatedChild = this.nestedChildren.find((uc) => uc.id === child.id);
          if (updatedChild) {
            syncAttributes(child, updatedChild);
          }

          if (child.children) {
            child.children.forEach((nestedChild: any) => {
              traverseAndUpdate(nestedChild);
            });
          }
        };

        this.editedBreakout.children.forEach((child: any) => {
          traverseAndUpdate(child);
        });
      },
      handleEmptyValue(child: any, attribute: string, value: string) {
        if (child[attribute]) {
          child[attribute].value = value || '';
        } else {
          child[attribute] = value || NA_ATTRIBUTE_VALUE;
        }
      },
      getColor(colorName: keyof typeof this.colorHexMap) {
        return {
          color: this.colorHexMap[colorName] || 'rgba(0, 0, 0, 0)',
        };
      },
    },
  });
</script>

<style scoped>
  .modal-title {
    background-color: #3b516b;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .close-icon {
    cursor: pointer;
  }
  .branches-table {
    width: 100%;
    border: 1px solid #e0e0e0;
  }
  .table-row {
    display: flex;
    flex-direction: row;
    height: 50px;
    border-bottom: 1px solid #e0e0e0;
    overflow: hidden;
  }
  .table-row:hover {
    background-color: #f9f9f9;
  }

  .header {
    font-weight: bold;
    background-color: #f5f5f5;
  }
  .table-cell {
    padding: 0 8px;
    display: flex;
    align-items: center;
    width: 15%;
    overflow: hidden;
  }
  .table-cell v-text-field {
    max-height: 100%;
  }
  .branch {
    background-color: #f9f9f9;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    border: 1px solid rgb(96, 125, 139);
  }
</style>
